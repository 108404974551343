import { ChakraProvider } from "@chakra-ui/react";
import Header from "./BenAsensoCompo/Components/Header";
import Home from "./BenAsensoCompo/Home";

import Blog from "./BenAsensoCompo/Pages/Blog";
import PrivacyPolicy from "./BenAsensoCompo/Pages/PrivacyPolicy";
import NotFoundPage from "./BenAsensoCompo/Pages/NotFoundPage";

import Footer from "./BenAsensoCompo/Components/Footer";
import { Routes, Route, Router } from "react-router-dom";

//mine
import React, {useState, Suspense, lazy} from 'react'
import BitcoinBlog from "./BenAsensoCompo/Blogs/BitcoinBlog";
import BearsBlog from "./BenAsensoCompo/Blogs/BearsBlog";
import SignalsBlog from "./BenAsensoCompo/Blogs/SignalsBlog";
import InvestorBlog from "./BenAsensoCompo/Blogs/InvestorBlog";

import {HelmetProvider} from "react-helmet-async"
import { createTheme, ThemeProvider } from "@mui/material";




function App() {
  
  let theme = createTheme()


  let [selected, setSelected] = useState("Home")


  //Creating toggle for background switch
  let [toggle, setToggle] = useState(false)
  


  return(
    <div>
      <ThemeProvider theme={theme}>

       <ChakraProvider>

          <HelmetProvider>
          <Header toggleProp={toggle} setToggle={setToggle} selected={selected} setSelected={setSelected}/>

          <Routes>
         
         <Route path="/" element={<Home  toggleProp={toggle} selected={selected} setSelected={setSelected} toggle={toggle} setToggle={setToggle} />} />

          <Route path="/blog" element={<Blog  toggleProp={toggle} setToggle={setToggle} setSelected={setSelected} />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy toggleProp={toggle} setToggle={setToggle} />} />
          <Route path="/blog/bitcoin-jumps-to-highest-since-august" element={<BitcoinBlog  toggleProp={toggle} setToggle={setToggle} />} />
          <Route path="/blog/the-bears-come-back-to-bother-the-bulls" element={<BearsBlog  toggleProp={toggle} setToggle={setToggle} />} />
          <Route path="/blog/conflicting-signals-erratic-movements" element={<SignalsBlog  toggleProp={toggle} setToggle={setToggle} />} />
          <Route path="/blog/investor-optimism-is-about-to-be-tested" element={<InvestorBlog  toggleProp={toggle} setToggle={setToggle} />} />

          <Route path="*" element={<NotFoundPage toggleProp={toggle} setToggle={setToggle} />} />


          </Routes>

          <Footer />
          </HelmetProvider>
      
    </ChakraProvider>
    </ThemeProvider>

    </div>
  )
}

export default App;
