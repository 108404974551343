//Import css
import "./Page.css"

import React, {createElement, useEffect, useState} from "react";
import {useBreakpointValue,Box, HStack, Alert, AlertIcon, Slide,Center,Heading,Image,VStack, SimpleGrid, Card, Text, Link,CardHeader, CardBody, LinkBox, LinkOverlay, Button, Flex, FormHelperText, CardFooter, Avatar, Tag, TagLabel, Divider, Textarea, Checkbox, Container} from "@chakra-ui/react";
import ReactDOM from "react-dom";
import bitcoin from "../Assets/bitcoin.jpg"
import Pavt from "../Assets/Pavt.jpeg"
import Loadspinner from "../Components/Loadspinner";
import { ChakraProvider } from "@chakra-ui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faReply, faUser, faWarning } from "@fortawesome/free-solid-svg-icons";
import {Link as RouterLink} from 'react-router-dom'
import {HashLink} from "react-router-hash-link"
import {Formik, formik, ErrorMessage, Form, Field, validateYupSchema} from 'formik'
import { FormControl, FormErrorMessage,  Input, FormLabel } from "@chakra-ui/react";
import * as Yup from "yup"

import app from "../Components/firebase"
import {getFirestore, collection, addDoc, doc,onSnapshot, serverTimestamp, Timestamp, query, orderBy, getDocs} from "firebase/firestore"
import { ref, onValue, getDatabase} from "firebase/database"
import { Helmet } from "react-helmet-async"








function BitcoinBlog({toggleProp, setToggle}){

let [loading, setLoading] = useState(true)


let dataB = getFirestore(app)
let database = getDatabase(app)

let connectionStatus = ref(database, ".info/connected")

let [online, setOnline] = useState(false)
let [alerterrormessage, setalerterrormessage] = useState(false)
let [isOpenError, setIsOpenError] = useState(false)


  let [initialValues, setInitialvalues] = useState({
  comment: "",
  name: "",
  email: "",
  website: "",
  check: false
})

let validationSchema = Yup.object({
  comment: Yup.string().required("Comment is required").min(25, "Minimum characters is 25"),
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  website: Yup.string(),
  check: Yup.string()
})


let comment = document.getElementById("new_comment");
let name = document.getElementById("name")



let [numOfDocs, setNumOfDocs] = useState()
let [replies, setReplies] = useState()



 
let AvatarComponent2=({commentData, time, replyForm, ReplyButton, docID})=> {

  
return( <div>
  <ChakraProvider>
  <Box >
    <HStack>
    <Avatar name={commentData.name}  />
      <HStack ml={4} alignItems="start" >
      <FontAwesomeIcon icon={faUser} />
      <Text mt={-1}>{commentData.name}</Text>
      </HStack>
    </HStack>
    <Text mt={4}>{time.toDate().toLocaleString("en-US", {year:"numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"})} </Text>
    <Text >{commentData.comment}</Text>
    <HStack mt={10} alignItems="start" cursor="pointer">
     {<ReplyButton/>}
      {replyForm}
    
    </HStack>

  </Box>
  </ChakraProvider>

</div>  
)
}



let AvatarComponentReplies=({replyCommentsData, replytime})=> {

return( <div>
  <ChakraProvider>
  <Box >
    <HStack>
    <Avatar name={replyCommentsData.name} src={replyCommentsData.email == "bengazy207!@gmail.com" && Pavt}  />
      <HStack ml={4} alignItems="start" >
      <FontAwesomeIcon icon={faUser} />
      <Text mt={-1}>{replyCommentsData.name}</Text>
      </HStack>
    </HStack>
    <Text mt={4}>{replytime.toDate().toLocaleString("en-US", {year:"numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric"})} </Text>
    <Text >{replyCommentsData.comment}</Text>
    <HStack mt={10} alignItems="start" cursor="pointer">

    </HStack>

  </Box>
  </ChakraProvider>

</div>  
)
}



useEffect((e)=>{
  let connectionStatusListeneer = onValue(connectionStatus, (snapshot)=>{
    setOnline(snapshot.val())
  })
  return()=>{
    connectionStatusListeneer()
  }
}, [])



let addComment = async(values, actions) => {

    if(online){      

    try{
      let formCollection = collection(dataB, "bitcoinComments")
      //let docRef = doc(formCollection) // Reference to a new document in the formCollection
      //let subCollection = collection(docRef, "bitcoinComments")
      await addDoc(formCollection, {...values, timeStamp: serverTimestamp()})
      values.check && localStorage.setItem("UserData", JSON.stringify(values))
      console.log("Form submitted successfully")
      actions.resetForm()
    
    }
    
    catch (error){
      console.error("Error submitting data", error.message)
      actions.setSubmitting(false)
    }
  }

  else{
      setalerterrormessage(true)
      setIsOpenError(true)

      setTimeout((e) => {
      setIsOpenError(false)
    }, 5000);

  }

  }



// Fetching comments from firestore to display it

/*
useEffect((e)=>{
  
  let userData = localStorage.getItem("UserData")
  if (userData){

  let parseUserData = JSON.parse(userData)
  initialValues.name = parseUserData.name
  initialValues.email = parseUserData.email
  initialValues.website = parseUserData.website   
  }

  
  let func = ()=>{  

  let formCollection = collection(dataB, "bitcoinComments")
  let querycollection = query(formCollection, orderBy("timeStamp", "desc"))
  // onSnaphot is to listen for changes in the firestore collection
  onSnapshot(querycollection, (snapshot)=>{  

  let newContainer = document.createElement("div")
  newContainer.setAttribute("id", "all_comments" )

  setNumOfDocs(snapshot.size)

  snapshot.forEach((doc)=>{
    let commentData = doc.data()
    let time = commentData.timeStamp
    let docID = doc.id
    
    let commentDiv = document.createElement("div")
    commentDiv.setAttribute("class", "comment")
    //The ReactDOM.render components is unable to take/accept state passed from the parent componenet to it hence we did it here and passed it as a prop to render in it
    let ReplyButton = ()=>{
    return(
      <div>
        <Button colorScheme="facebook" onClick={(e)=> setReplies(docID) } fontWeight="bold" > <FontAwesomeIcon icon={faReply}/> <span style={{paddingLeft: 10}}>Reply</span></Button>
      </div>
    )
    }
    
    let replyForm = replies === docID && <ReplyForm toggleProp={toggleProp} commentData={commentData} docID={docID} />
    ReactDOM.render(<AvatarComponent2 commentData={commentData} time={time} docID={docID} ReplyButton={ReplyButton} replyForm={replyForm} />, commentDiv)
    //commentDiv.innerHTML = `<p>${commentData.comment}</p>`
    newContainer.appendChild(commentDiv)
    setLoading(false)
  })

  let oldContainer = document.getElementById("all_comments")
  if(oldContainer){
    oldContainer.parentNode.replaceChild(newContainer, oldContainer)
  }


})

}
  func()

},[replies, toggleProp])

*/


// Fetching comments + RepliesComments from firestore to display it under parentComment

useEffect((e)=>{
  

  let userData = localStorage.getItem("UserData")
  if (userData){

  let parseUserData = JSON.parse(userData)
  initialValues.name = parseUserData.name
  initialValues.email = parseUserData.email
  initialValues.website = parseUserData.website   
  }
  
  let func = ()=>{  

  let formCollection = collection(dataB, "bitcoinComments")
  let querycollection = query(formCollection, orderBy("timeStamp", "desc"))
  // onSnaphot is to listen for changes in the firestore collection
  onSnapshot(querycollection, async(snapshot)=>{  
  // Iterate through each parent comment to get the number of replies. Snapshot.docs means all the documents in the query
    let totalReplies = 0
    for (let parentDoc of snapshot.docs){
      let parentCommentId = parentDoc.id
      let repliesCollect = collection(dataB, "bitcoinComments", parentCommentId, "bitcoinReplyComments") // The docID here means get the collection at this id      
      let repliesQuery = query(repliesCollect)
      let getReplyDocs = await getDocs(repliesQuery)
      totalReplies += getReplyDocs.size
    }
    
    setNumOfDocs(snapshot.size + totalReplies)

  let newContainer = document.createElement("div")
  newContainer.setAttribute("id", "all_comments" )


  snapshot.forEach((doc)=>{
    let commentData = doc.data()
    let time = commentData.timeStamp
    let docID = doc.id
  
    let commentDiv = document.createElement("div")
    commentDiv.setAttribute("class", "comment")

    // Create a container for the parent comment and its replies. This ensures each reply display beneath each parent comment perfectly.
    let commentsWithRepliesContainer = document.createElement("id")
    commentsWithRepliesContainer.setAttribute("class", "all_comments")
    
    // The ReactDOM.render components is unable to take/accept state passed from the parent componenet to it hence we did it here and passed it as a prop to render in it 
    let ReplyButton = ()=>{
    return(
      <div>
        <Button colorScheme="facebook" onClick={(e)=> setReplies(docID) } fontWeight="bold" > <FontAwesomeIcon icon={faReply}/> <span style={{paddingLeft: 10}}>Reply</span></Button>
      </div>
    )
    }
    let replyForm = replies === docID && <ReplyForm toggleProp={toggleProp} commentData={commentData} docID={docID} />
    ReactDOM.render(<AvatarComponent2 commentData={commentData} time={time} docID={docID} replyForm={replyForm} ReplyButton={ReplyButton} />, commentDiv)
    //newContainer.appendChild(commentDiv)
    commentsWithRepliesContainer.appendChild(commentDiv)
    setLoading(false)

    
    // Fetching replies from firestore to display beneath parentComment
    let repliesCollection = collection(dataB, "bitcoinComments", docID, "bitcoinReplyComments") // The docID here means get the collection at this id
    let queryRepliesCollection = query(repliesCollection, orderBy("timeStamp", "desc"))

    onSnapshot(queryRepliesCollection, (repliesSnapshot)=>{

      repliesSnapshot.forEach(async(replydoc)=>{
        let replyCommentsData = replydoc.data()
        let replytime = replyCommentsData.timeStamp
        let replyDocID = replydoc.id

        let replyDiv = document.createElement("div")
        replyDiv.setAttribute("class", "reply")
        
        ReactDOM.render(<AvatarComponentReplies replyCommentsData={replyCommentsData} replytime={replytime} replyDocID={replyDocID}  />, replyDiv)
        //commentDiv.innerHTML = `<p>${commentData.comment}</p>`
        commentsWithRepliesContainer.appendChild(replyDiv)
      })
    })
        
    //Now append the commentWithRepliesContainer with the main container/ AKA the mothership of container
    newContainer.appendChild(commentsWithRepliesContainer)

  })

  let oldContainer = document.getElementById("all_comments")
  if(oldContainer){
    oldContainer.parentNode.replaceChild(newContainer, oldContainer)
  }

})

}
  func()

},[replies])



let ReplyForm = ({toggleProp, commentData, docID})=>{
  
let addReplyComment = async(values, actions) => {

  let parentCommentId = docID

    if(online){      

    try{
      let formCollection = collection(dataB, "bitcoinComments")
      let parentCommentDocRef = doc(formCollection, parentCommentId) // Reference to a new document in the formCollection  //2. Here we included the because we wanted the doc to be at the parent doc id
      let subCollection = collection(parentCommentDocRef, "bitcoinReplyComments")
      await addDoc(subCollection, {...values, timeStamp: serverTimestamp()})
      values.check && localStorage.setItem("UserData", JSON.stringify(values))
      console.log("Form submitted successfully")
      actions.resetForm()
      setReplies()
    
    }
    
    catch (error){
      console.error("Error submitting data", error.message)
      actions.setSubmitting(false)
    }
  }

  else{
      setalerterrormessage(true)
      setIsOpenError(true)

      setTimeout((e) => {
      setIsOpenError(false)
    }, 5000);

  }

  }
  
  
  
  return(
    <div>

      <VStack ml={-24} mr={4} id="forms">        

      <Box mt={20}>
          <HStack margin={5} marginBottom={0} width="100%" justifyContent="space-between" >
          <Heading   as="h2" size={mobileView? "md": (tabletView? "xl": "2xl")}  id="services-section">Reply to {commentData.name}</Heading>
          <Heading as="h2" color="green" size={mobileView? "md": "lg"}  onClick={(e)=> setReplies()} >Cancel reply</Heading>
          </HStack>
      </Box>

        <Text pt={10} textAlign="center" fontSize={mobileView? "xl": "2xl"}  style={{margin: 5}} > 
            Your email will not be published. Required fields are marked *
        </Text>

          
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addReplyComment}
        >

        {(formik) =>(
          
          <Form >
            <FormControl isInvalid={!!formik.errors.comment && formik.touched.comment} >
              <FormLabel htmlFor="comment">Comment <sup style={{marginLeft: 4}}>*</sup></FormLabel>
             <Field as={Textarea} id="new_comment" fontSize={mobileView? "md": "xl"}  name="comment" style={{border: "2px solid black"}} minHeight={250}  placeholder="Message..." />  

              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="comment" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.name && formik.touched.name} mt={4}>
              <FormLabel htmlFor="name">Name <sup style={{marginLeft: 4}}>*</sup></FormLabel>
              <Field as={Input} fontSize={mobileView? "md": "xl"}  name="name" id="name" style={{border: "2px solid black"}}  /> 
              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="name" />
              </FormErrorMessage>

            </FormControl>
            
            <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={4}>
              <FormLabel htmlFor="email">Email <sup style={{marginLeft: 4}}>*</sup></FormLabel>
              <Input as={Field} fontSize={mobileView? "md": "xl"} name="email" id="email" style={{border: "2px solid black"}}  />
              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="email" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.website && formik.touched.website} mt={4}>
              <FormLabel htmlFor="website">Website </FormLabel>
              <Input as={Field} fontSize={mobileView? "md": "xl"} name="website" id="website" style={{border: "2px solid black"}}  />
              <FormErrorMessage>
              <ErrorMessage name="website" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.check && formik.touched.check} mt={4}>
  
              <Field as={Checkbox} name="check" id="check" size={mobileView? "md": "lg"} >Save my name, email and website in this browser for the next time I comment.</Field>
            
            </FormControl>

            <Button type="submit" mt="4" width="full" colorScheme="purple" isLoading={formik.isSubmitting}>Post Comment</Button>

          </Form>
        )}
          
        </Formik>
        </VStack>
    
    </div>
  )
}


let mobileView = useBreakpointValue({
  base: true,
  md: false
})


let tabletView = useBreakpointValue({
  md: true, 
  lg: false
})




if (loading) {
  return(
    <div> <Loadspinner toggleProp={toggleProp} /></div>
  )
 }



  return(
    <div>
        <Helmet>
          <title>Bitcoin Jumps to Highest Since August | Ben Asenso: Blog</title>
        </Helmet>

        <VStack maxWidth="full" padding={10} alignItems="left" spacing={8} backgroundColor={toggleProp? "#18181b":"white"} color={toggleProp? "white": "black"} pl={tabletView? 10: (mobileView? 5: 40)} pr={tabletView? 10: (mobileView? 5: 40)} >        
        
          <Center>
          <Heading margin={5} paddingTop={20} marginBottom={0} as="h2" size={mobileView? "xl": "2xl"} id="1" >Bitcoin Jumps to Highest Since August</Heading>
          </Center>

        
          <Text fontSize={mobileView? "xl": "2xl"} color={toggleProp? "white": "#64748b"} style={{margin: 5}} > 
            Bitcoin rose to the highest level since August on Wednesday, gaining for a second day as a decrease in risk aversion helps 
            to temper concerns about a widening crackdown by US regulators.
          </Text>

          <Card bg={toggleProp && "#18181b"} >

          <CardFooter p={8} pt={-8} pl={-8} >
                <HStack>
                  <Avatar size="lg" src={Pavt} name="Ben"/>
                  <VStack fontSize="xl" alignItems="start" spacing={0} color={toggleProp &&"white"}>
                    <Text margin={0} fontWeight="bold">Ben Asenso</Text>
                    <Text margin={0} >July 12, 2023</Text>
                  </VStack>
                </HStack>
          </CardFooter>
          

          <CardBody pt={4} pl={0} pr={0} >
                <Box overflow="hidden">
                <Image src ={bitcoin} height={mobileView? "sm": "lg"} width="full" objectFit="cover" _hover={{transform: "scale(1.1)", transition:"transform ease-in-out 0.3s"}} alt="Bitcoin jumps to highest since August" />      
                </Box>
                
            <Text fontSize={mobileView? "md": "xl"} color={toggleProp? "white": "#64748b"}  style={{margin: 5, paddingTop: 60}}>  
            Bitcoin, the leading cryptocurrency, surged to its highest level since August, marking a significant 
            uptrend amidst a backdrop of regulatory scrutiny and market volatility. The digital asset witnessed a 
            remarkable 8.2% surge, reaching a price point of $24,083, representing its most substantial one-day gain in 
            approximately a month.
            This surge comes at a time when concerns over regulatory crackdowns by US authorities have been escalating. 
            The crypto market has been navigating through turbulent waters, grappling with a series of enforcement actions and 
            regulatory warnings issued by the US Securities and Exchange Commission (SEC). <br/><br/>
            Amidst this regulatory environment, various digital tokens have experienced heightened volatility. Notably, smaller 
            cryptocurrencies such as Ether, Cardano, and Polkadot joined Bitcoin in rallying amidst the uncertainty, suggesting a 
            broader market sentiment of resilience and optimism.
            Recent developments in the crypto space have added to the turbulence. Kraken, a prominent cryptocurrency exchange, reached 
            a settlement last week to terminate its staking product, reflecting the increasing pressure faced by industry players to comply 
            with regulatory requirements. Similarly, Paxos's decision to halt the issuance of its Binance-branded BUSD token has Recent developments 
            in the crypto space have added to the turbulence. Kraken, a prominent cryptocurrency exchange, reached a settlement last week to terminate 
            its staking product, reflecting the increasing pressure faced by industry players to comply with regulatory requirements. Similarly, Paxos's decision 
            to halt the issuance of its Binance-branded BUSD token has raised concerns among market participants, fueling speculation about potential regulatory actions against stablecoins like Circle’s USDC token.<br/><br/>
            Despite the regulatory headwinds, Bitcoin's resilience and its ability to weather market uncertainties underscore its growing acceptance and resilience as a digital asset. The recent surge to its highest 
            level in months reflects investors' confidence in its long-term potential, even amidst regulatory challenges.
            Looking ahead, the crypto market remains on edge, closely monitoring regulatory developments and their potential impact on the ecosystem. However, Bitcoin's recent surge serves as a testament to its 
            underlying strength and its position as a leading asset in the evolving landscape of digital finance. As the regulatory landscape continues to evolve, the resilience of cryptocurrencies like Bitcoin 
            will be tested, yet their disruptive potential remains undeniable. 
            While regulatory uncertainties continue to cast a shadow over the crypto market, Bitcoin's recent surge highlights its resilience and enduring appeal to investors. As the industry navigates through 
            challenges, Bitcoin's position as a frontrunner in the digital asset space remains unshaken, signaling a promising future amidst evolving regulatory dynamics.<br/><br/>
            Moreover, beyond the regulatory landscape, Bitcoin's surge reflects broader market dynamics and investor sentiment. The ongoing macroeconomic backdrop, characterized by inflationary pressures and 
            central bank policies, has fueled interest in alternative assets like Bitcoin, which are seen as hedges against currency devaluation and economic uncertainty. Additionally, institutional adoption 
            and investment in Bitcoin have been steadily increasing, further bolstering its legitimacy and long-term viability as a store of value.
            Furthermore, technological advancements and innovations within the crypto ecosystem continue to enhance Bitcoin's utility and accessibility. Developments such as the Lightning Network for faster 
            and cheaper transactions, as well as the integration of Bitcoin into traditional financial services, are expanding its use cases and driving adoption among mainstream users and institutions alike.<br/><br/>
            In the context of geopolitical tensions and global macroeconomic shifts, Bitcoin's decentralized nature and censorship-resistant properties position it as a resilient and globally accessible asset. As 
            traditional financial systems face challenges and vulnerabilities, Bitcoin stands out as a decentralized alternative that offers individuals and institutions greater control over their financial sovereignty. 
            As we navigate through the complexities of the digital age, Bitcoin's ascent to new heights underscores its role as a transformative force in reshaping the future of finance. While regulatory uncertainties may 
            persist, the underlying fundamentals and value proposition of Bitcoin remain robust, paving the way for continued growth and innovation in the digital asset space.


            </Text>

          </CardBody>

          <CardFooter pt={0} pl={0} pr={0}>
          <HStack>
                <Tag size={mobileView? "sm": "lg"} transform="scale(1.4)" colorScheme="teal" margin={5} mt={14}  >
                  <TagLabel >Finance</TagLabel>
                </Tag>

                <Tag size= {mobileView? "sm": "lg"} colorScheme="blue" transform="scale(1.4)" margin={5} mt={14} >
                  <TagLabel>Management</TagLabel>
                </Tag>
                </HStack>

          </CardFooter>

        </Card>
        

        <Card bg={toggleProp? "#18181b": "gray.100" } color={toggleProp && "white"}  p={mobileView? 5: 20} mb={10}  >
        
  
      
      <h2>{numOfDocs} Comments</h2>
      <div class="all_comments" id="all_comments" >
        
      </div>
  
      


      {!replies &&
        <>
        <Center mt={10}>
          <Heading margin={5} marginBottom={0} as="h2" size={mobileView? "xl": "2xl"}  id="services-section">Leave a Reply</Heading>
        </Center>

        <Text pt={10} textAlign="center" fontSize={mobileView? "xl": "2xl"} color={toggleProp && "white"} style={{margin: 5}} > 
            Your email will not be published. Required fields are marked *
        </Text>

              
        
    
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={addComment}
        >

        {(formik) =>(
          
          <Form id="form">
            <FormControl isInvalid={!!formik.errors.comment && formik.touched.comment} >
              <FormLabel htmlFor="comment">Comment <sup style={{marginLeft: 4}}>*</sup></FormLabel>
             <Field as={Textarea} id="new_comment" fontSize={mobileView? "md": "xl"}  name="comment" style={{border: !toggleProp && "2px solid black"}} minHeight={250}  placeholder="Message..." />  

              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="comment" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.name && formik.touched.name} mt={4}>
              <FormLabel htmlFor="name">Name <sup style={{marginLeft: 4}}>*</sup></FormLabel>
              <Field as={Input} fontSize={mobileView? "md": "xl"}  name="name" id="name" style={{border: !toggleProp && "2px solid black"}}  /> 
              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="name" />
              </FormErrorMessage>

            </FormControl>
            
            <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={4}>
              <FormLabel htmlFor="email">Email <sup style={{marginLeft: 4}}>*</sup></FormLabel>
              <Input as={Field} fontSize={mobileView? "md": "xl"} name="email" id="email" style={{border: !toggleProp && "2px solid black"}}  />
              <FormErrorMessage>
              <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
              <ErrorMessage name="email" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.website && formik.touched.website} mt={4}>
              <FormLabel htmlFor="website">Website </FormLabel>
              <Input as={Field} fontSize={mobileView? "md": "xl"} name="website" id="website" style={{border: !toggleProp && "2px solid black"}}  />
              <FormErrorMessage>
              <ErrorMessage name="website" />
              </FormErrorMessage>

            </FormControl>

            <FormControl isInvalid={!!formik.errors.check && formik.touched.check} mt={4}>
  
              <Field as={Checkbox} name="check" id="check" size={mobileView? "md": "lg"} >Save my name, email and website in this browser for the next time I comment.</Field>
            
            </FormControl>

            <Button type="submit" mt="4" width="full" colorScheme="purple" isLoading={formik.isSubmitting}>Post Comment</Button>

          </Form>
        )}
          
        </Formik>
        </>
      }

      

        </Card>


           

          
          {alerterrormessage && 
            <Slide in={isOpenError} style={{marginTop: 40}}>
            <Alert status="error" mt={5} >
              <AlertIcon />
              <span style={{color:"black"}}>You are offline. Please check your internet connection and try again.</span>
            </Alert>

            </Slide> }
      

        
        <Heading  marginBottom={0} as="h2" size= "xl" id="services-section">Recent articles</Heading>
         
          <Link as={HashLink} width="fit-content" smooth to="/blog/bitcoin-jumps-to-highest-since-august#1" fontSize="xl" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}  color={toggleProp? "white": "#64748b"} style={{marginBottom:-10}} >
          Bitcoin Jumps to Highest Since August
          </Link>

          <Link as={HashLink}smooth to="/blog/the-bears-come-back-to-bother-the-bulls#2" fontSize="xl" width="fit-content" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color={toggleProp? "white": "#64748b"} style={{marginBottom:-10}} >
          The Bears Come Back to Bother the Bulls
          </Link>

           <Link as={HashLink} smooth to="/blog/conflicting-signals-erratic-movements#3" fontSize="xl" width="fit-content"  _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color={toggleProp? "white": "#64748b"}  style={{marginBottom:-10}}>
          Conflicting Sinals, Erratic Movements
          </Link>

           <Link as={HashLink} smooth to="/blog/investor-optimism-is-about-to-be-tested#4" fontSize="xl" width="fit-content" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} color={toggleProp? "white": "#64748b"}  mb={20}>
          Investor Optimism is About to Be Tested
          </Link>
      



        </VStack>    
    </div>
  )
}



export default BitcoinBlog;
