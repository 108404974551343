import React, {useState, useEffect } from "react";
import { Avatar, Center,HStack,Text,Heading, VStack, useBreakpointValue, Image, Container, Box, background, Link} from "@chakra-ui/react";
import {useSpring, useScroll,animated, useTrail, useSprings} from "react-spring"
import Pavt from "../Assets/Pavt.jpeg"
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Slider from "react-slick"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import post01 from "../Assets/post01.jpg"
import post02 from "../Assets/post02.jpg"
import home from '../Assets/home.jpg'
import home01 from "../Assets/home01.jpg"
import home02 from "../Assets/home02.jpg"
import post04 from "../Assets/post04.jpg"
import gallery01 from "../Assets/gallery01.jpg"
import gallery02 from "../Assets/gallery02.jpg"
import gallery03 from "../Assets/gallery03.jpg"
import banner from "../Assets/banner.jpg"
import banner01 from "../Assets/banner01.jpg"
import banner02 from "../Assets/banner02.jpg"
import logo from "../Assets/logo.png"
import benLogo  from "../Assets/Ben-Asenso-Logo.png"

import html from "../Assets/html5.png"
import css3 from "../Assets/CSS3.png"
import js from "../Assets/jss.png"
import python from "../Assets/python.png"
import reacts from "../Assets/react.png"
import nodejs from "../Assets/nodejs.png"
import boot from "../Assets/boot.png"
import tailwind from "../Assets/tailwind.png"
import restapi from "../Assets/Rest API.png"
import mysql from "../Assets/my-sql.png"
import django from "../Assets/django.png"
import github from "../Assets/git.jpg"


import {Typewriter} from 'react-simple-typewriter'
import { useInView } from "react-intersection-observer";
import BackgroundSlider from 'react-background-slider'
import { Helmet } from "react-helmet-async"
import Marquee from 'react-marquee-slider'



const greeting = "Hello, I am Pete!";
const bio1 = "A frontend developer";


let images = [post01, post02, home, home01, post04, gallery01, gallery02, gallery03, banner, banner01, banner02]
let logos = [html, css3, js, reacts, python, mysql, boot, restapi, django, tailwind, nodejs, github]


function LandingSection({toggleProp, setSelected}){

  let [visible, setVisible] = useState(false)
  let [visible2, setVisible2] = useState(false)
  let [visible3, setVisible3] = useState(false)

  
  let {ref: refHome, inView: inViewHome} = useInView({triggerOnce: false})

  useEffect((e)=>{
    if(inViewHome){
      setSelected("Home")
    }
  }, [inViewHome])


  
  let [loadedImage, setLoadedImage] = useState(false)
  let [loadedImage2, setLoadedImage2] = useState(false)


  let onLoad =(e)=>{
    setLoadedImage(true)
  }

  let onLoad2 =(e)=>{
    setLoadedImage2(true)
  }




  let mobileView  = useBreakpointValue({
    base: true,
    md: false
  })

  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })





 if (mobileView){
 return(
  <div >
      <Helmet>
        <title>Ben Asenso</title>
      </Helmet>
    
    <Container maxWidth="full" paddingLeft={0} paddingRight={0} paddingBottom="10" position="relative"  backgroundColor={loadedImage? "transparent":"rgba(0,0,0,0.8)"}>

    <div style={{minHeight: "100vh"}}>
    {/* Every position="absolute" needs a zIndex for functionality sake */}
      <Slider fade arrows={false} autoplay autoplaySpeed={5000} speed={2000} style={{position:"absolute", top:0, bottom:0, left:0, right:0, width:"100%", overflow:"hidden", zIndex:-5}} >

        {images.map((f, index)=>{
          return <div key={index}>
                {/* You can reduce the viewport height if you put it in a container and reduce that container viewport height */}
                {loadedImage? <Image src={f} width="100%" minHeight="200vh" objectFit="cover" /> :""}

                 {/*Listener for loaded image */}
                <img src={f} onLoad={onLoad} style={{display:"none"}} />

          </div>
          
        })}       
        </Slider>        
        {/* Creating overlay */}
        <div style={{
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: -5,
        background: "rgba(0,0,0,0.8)"
        }}>
        </div>

      
        {/* You can use two position="relative" to block the item from moving, thus its contained */ }        
        <div>
          {/* <h1>Content here</h1> */}
          
          <Box style={{paddingTop:"120px"}} paddingBottom={20} position="relative">
          <VStack spacing="16" color="white" justifyContent="center" id="home" >
            <VStack spacing="4" >
              <Avatar  size="2xl" name="Ben" src={Pavt} ></Avatar>
            
              <Heading as="h4" size="md" ref={refHome} >
                Hello, I'm Ben!
              </Heading>
            </VStack>

            <VStack spacing="6">
              <Heading as= "h1" size="xl"  noOfLines={2}>
                A software developer
              </Heading>
              <Heading as="h1" size="xl"  noOfLines={2}>
              specialised in React <br/><span style={{lineHeight:1.5 }}>and Django</span> 
              </Heading>

              <HStack>
                <Heading color="yellow">
                <Typewriter words={['Web developer', 'Digital marketer', 'Data analyst' ]} loop={false} cursor />
                </Heading>
              </HStack>

              <HStack>
              <Text fontSize="xl" ml={5}>
                I'm a passionate full stack software developer with a keen interest for detail and a knack for cafting immersive user experiences.
                I strive to bring creativity to every project I touch. From sleek interfaces to seamless interactions, I thrive on turninig ideas into 
                dynamic digital realities. Let's collaborate and bring your visions to life!
              </Text>
            </HStack>
            
            </VStack>
          

          </VStack>


          </Box>

        </div>

    </div> <br/>
      


      {/* Creating logo*/}
    {/*  <Image src={logo} position="absolute" top= "10%" left="5" boxSize="50px" /> */}
      <Link href="https://benasenso.site">
      <Image src={benLogo} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "6%" left="3" boxSize="120px" height={5}/> 
      </Link>


       {/* The postion="absolute" should'nt interfere with your components  */}
       <div style={{width:'100%', height:'140px', overflow:'hidden', background:"black"}}>
          <Marquee  velocity={20} minScale={1} resetAfterTries={200} >
            {logos.map((f, index)=>{
            return  <div key={index} style={{display:"flex", alignItems:"center",padding: '20px', paddingTop:"0px", paddingBottom:"0px",borderRight:loadedImage2&& "1px solid white", marginTop:35}}  > 
                {loadedImage2? <Image src={f} loading="lazy" style={{height:"60px", width:"60px"}} /> :""}

                  {/*Listener for loaded image */}
                  <img src={f} onLoad={onLoad2} style={{display:"none"}} />

              </div>
            })}
          </Marquee>
        </div>

      
    </Container>    

    

  </div>
  )
 }
 else{
 
 return(
    <div>
      <Helmet>
        <title>Ben Asenso</title>
      </Helmet>

      <Container paddingLeft={0} paddingRight={0} maxWidth="full" paddingBottom="10" position="relative" backgroundColor={loadedImage? "transparent":"rgba(0,0,0,0.8)"}>
        
      <div style={{minHeight: "60vh"}} >
        {/* Every position="absolute" needs a zIndex for functionality sake */}
      <Slider fade arrows={false} autoplay autoplaySpeed={5000} speed={2000} style={{position:"absolute", top:0, bottom:0, left:0, right:0, width:"100%", overflow:"hidden", zIndex:-5}} >

        {images.map((f, index)=>{
          return <div key={index}>
                {/* You can reduce the viewport height if you put it in a container and reduce that container viewport height */}
            {loadedImage? <Image src={f} width="100%" minHeight="200vh" objectFit="cover" /> :""}
              
              {/*Listener for loaded image */}
              <img src={f} onLoad={onLoad} style={{display:"none"}} />

          </div>
          
        })}       
        </Slider>
        {/* Creating overlay */}
        <div style={{
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        zIndex: 0,
        background: "rgba(0,0,0,0.8)",
        
        }}>
        </div>

      
      {/* You can use two position="relative" to block the item from moving, thus its contained */ }
      <div>
      {/* <h1>Content here</h1> */}
    
      <Box paddingTop={40} paddingLeft={10} paddingRight={10} paddingBottom={20} position="relative">
        <VStack  spacing="16" color="white" textAlign="center" id="home">
          <VStack  spacing="4">
            <Avatar size="2xl" name="Ben" src={Pavt} ref={refHome} ></Avatar>
          
            <Heading as="h4" size="md" >
              Hello, I'm Ben!
            </Heading>
          </VStack>

          <VStack spacing="6" width={tabletView && "100vw"}>
            <Heading as= "h1" size="3xl" >
              A software developer
            </Heading>
            <Heading as="h1" size="3xl"  >
            specialised in React and <span style={{lineHeight:1.5 }}>Django</span>
            </Heading>

            <HStack  color="yellow">
              <Heading>
              <Typewriter words={['Web developer', 'Digital marketer', 'Data analyst' ]} loop={false} cursor />
              </Heading>
            </HStack>

            <HStack>
            <Text fontSize="2xl" ml={tabletView && 10} mr={tabletView && 10} >
              I'm a passionate full stack software developer with a keen interest for detail and a knack for cafting immersive user experiences.
              I strive to bring creativity to every project I touch. From sleek interfaces to seamless interactions, I thrive on turninig ideas into 
              dynamic digital realities. Let's collaborate and bring your visions to life!
            </Text>
          </HStack>
      
          </VStack>
          
        </VStack>
        
        </Box>

      </div>

    </div>  



     


     
         {/* Creating logo*/}
   {/*   <Image src={logo} position="absolute" top= "10%" left="5" boxSize="50px"  /> */}

      <Link href="https://benasenso.site">
      <Image src={benLogo} position="absolute" alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" top= "6%" left="5" boxSize="250px" height={10}/> 
      </Link>

     {/* The postion="absolute" should'nt interfere with your components  */}

      
      <div style={{width:'100%', height:'140px', overflow:'hidden', background:"black"}} >
          <Marquee  velocity={20} minScale={1} resetAfterTries={200} >
            {logos.map((f, index)=>{
            return  <div key={index} style={{display:"flex", alignItems:"center",padding:'20px', paddingTop:"0px", paddingBottom:"0px", borderRight:loadedImage2&& "1px solid white", marginTop:25}} > 
              {loadedImage2? <Image src={f} loading="lazy" style={{height:"80px"}} width={20} height={20} /> :""}
                
                {/*Listener for loaded image */}
                <img src={f} onLoad={onLoad2} style={{display:"none"}} />

              </div>
            })}
          </Marquee>
      </div>

      {/* marginLeft: tabletView? -15: -35 */}
      


      </Container>

     

    </div>
  )
}
}



export default LandingSection;
